<template>
  <div>
    <CModal
      :show.sync="stateDialog"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">{{onCheck(fields.evaluationPlan)}}</h6>
        <CButtonClose @click="onUpdatePlan" class="text-white"/>
      </template>
      <template #body-wrapper>
        <div class="p-2 pl-4 pr-4">
<!--          {{this.onCheck(this.fields.lessonPlans)}}-->
          <CRow>
            <CCol col="12">
              <p class="font-weight-bold">{{ onCheck(fields.evaluationPlans)[0].label }}</p>
              <quill-editor class="mb-3" :content="data.reason" @change="onChane"/>
            </CCol>
            <CCol col="12">
              <p class="font-weight-bold">{{ onCheck(fields.evaluationPlans)[1].label }}</p>
              <quill-editor class="mb-3" :content="data.reason" @change="onChane"/>
            </CCol>
            <CCol col="12">
              <p class="font-weight-bold">{{ onCheck(fields.evaluationPlans)[2].label }}</p>
              <quill-editor class="mb-3" :content="data.reason" @change="onChane"/>
            </CCol>
            <CCol col="12">
              <p class="font-weight-bold">{{ onCheck(fields.evaluationPlans)[3].label }}</p>
              <quill-editor class="mb-3" :content="data.reason" @change="onChane"/>
            </CCol>

          </CRow>
        </div>
      </template>
      <template #footer>
        <CButton @click="onUpdatePlan" color="danger">{{onCheck(fields.cancel)}}</CButton>
        <CButton @click="onUpdatePlanSubmit" color="success">{{onCheck(fields.save)}}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

// import QuillBetterTable from 'quill-better-table'
// Quill.register({
//   'modules/better-table': QuillBetterTable
// }, true)
export default {
  name: 'DialogEvaluationPlans',
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      lang: "th",
      darkModal: true,
      fields: {
        evaluationPlan: [
          {
            key: "th",
            value: "แผนการประเมินผลการเรียนรู้"
          },
          {
            key: "en",
            value: "Evaluation Plan"
          }
        ],
        evaluationPlans: [
          {
            key: "th",
            value: [
              {key: 'learning', label: 'ผลการเรียนรู้'},
              {key: 'method', label: 'วิธีการประเมิน'},
              {key: 'week', label: 'สัปดาห์ที่ประเมิน'},
              {key: 'proportion', label: 'สัดส่วนของการประเมิน'},
              {key: '#', label: "#", _style: 'width:2%; text-align: center'}
            ]
          }, {
            key: "en",
            value: [
              {key: 'learning', label: 'Domain of Learning'},
              {key: 'method', label: 'Assessment Method'},
              {key: 'week', label: 'Assessment Week'},
              {key: 'proportion', label: 'Assessment Proportion'},
              {key: '#', label: "#", _style: 'width:2%; text-align: center'}
            ]
          }
        ],
        save: [
          {
            key: "th",
            value: "บันทึก"
          },
          {
            key: "en",
            value: "Save"
          }
        ],
        cancel: [
          {
            key: "th",
            value: "ยกเลิก"
          },
          {
            key: "en",
            value: "Cancel"
          }
        ]
      },
      data: {
        reason:''
      }
    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },


    onChane(html, text, quill) {
      console.log(html)
    },

    onUpdatePlan(){
      this.$store.dispatch("dialogCreate/setDialogEvaluationPlans",false)
    },

    onUpdatePlanSubmit(){
      this.$store.dispatch("dialogCreate/setDialogEvaluationPlans",false)
    },
  },


  computed: {
    ...mapGetters({
      stateDialog : "dialogCreate/onGetDialogEvaluationPlans",
    })
  },

  watch: {

  }
}
</script>
