<template>
  <div>
    <CRow>
      <CCol>
        <p class="mt-2"><span class="font-weight-bold">1. {{ this.onCheck(this.fields.references) }} </span> </p>

        <CRow class="mb-2" v-for="(item, index) in data.item" v-bind:key="index">
          <CCol col="11">
            1.{{index+1}} {{onCheck(item.bookName)}}
          </CCol>
          <CCol col="1" class="text-right">
            <CButton size="sm" color="danger" shape="pill" variant="outline" @click="onRemove(item)">
              <CIcon name="cil-minus"/>
            </CButton>
          </CCol>
        </CRow>

        <!--    // add academicYears-->
        <CRow class="mt-2">
          <CCol>
            <CInput type="text" :horizontal="{label: 'col-sm-2 font-weight-bold', input: 'col-sm-10'}"
                    label="SBN"
                    v-model="reserve.bookName"/>
          </CCol>

          <CCol>
            <CInput type="text" :horizontal="{label: 'col-sm-2 font-weight-bold', input: 'col-sm-10'}"
                    :label="onCheck(fields.bookName)"
                    v-model="reserve.bookName"/>
          </CCol>

          <CCol col="1" class="text-right">
            <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAdd">
              <CIcon name="cil-plus"/>
            </CButton>
          </CCol>
        </CRow>
        <!--    // end add academicYears-->

      </CCol>
    </CRow>

  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: 'References',
  props: {
    disable: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      fields : {
        references: [{
          key: "th",
          value: "ระบุตำราเอกสารหลักที่ใช้ในการเรียนการสอน และ หรือ ตำราเอกสารสำคัญที่แนะนำให้นักศึกษาไปอ่านเพิ่มเติม (เขียนตามระบบ)"
        }, {
          key: "en",
          value: "Specify reading materials including textbooks, docments, suggested reading and or other resources (with standard referencing format)"
        }],
        bookName:[{
          key: "th",
          value: "ชื่อหนังสือ"
        }, {
          key: "en",
          value: "Book Name"
        }]
      },
      data:{
        item : []
      },
      reserve:{
        bookName:""
      },
      content : ''
    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {
    onCheckIndex(key,data) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          key[i].value = data;
        }
      }
    },
    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },
    onAdd(){
      var item = {
        bookName:[{
          key: "th",
          value:  (this.lang == "th")?this.reserve.bookName:""
        }, {
          key: "en",
          value:  (this.lang == "th")?this.reserve.bookName:""
        }]
      }

      this.data.item.push(item)
    },

    onRemove(item){
      this.data.item = this.data.item.filter(el => el !== item);
    }

  },



  computed: {
    ...mapGetters({
      lang: "setting/objLang",
    })
  },

  watch: {
    // Watch content change

  }
}
</script>
