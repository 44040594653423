<template>
  <CCard accent-color="secondary">
    <CCardHeader v-if="!icon">
      <slot name="header" >
        <CIcon v-if="icon" :name="icon"/> <span class="font-weight-bold">{{caption}}</span>
        <div class="card-header-actions">
          <CButton size="sm" color="success" shape="pill" variant="outline"> <CIcon name="cil-library-add"/> </CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody class="p-0">
      <CDataTable
        :hover="hover"
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        //

        <template #no="{index,item}">
          <td>
            <div class="text-center">{{index+1}}</div>
          </td>
        </template>

        <template #topics="{item}">
          <td>
            <div class="text-center">{{onCheck(item.topics)}}</div>
          </td>
        </template>
        <template #weekNumber="{item}">
          <td>
            <div class="text-center">{{item.weekNumber}}</div>
          </td>
        </template>
        <template #lectureHours="{item}">
          <td>
            <div class="text-center">{{item.lectureHours}}</div>
          </td>
        </template>

        <template #activity="{item}">
          <td>
            <div v-html="onCheck(item.activity)"></div>
<!--            <div v-html="item.activity"></div>-->
          </td>
        </template>

        <template #instructorName="{item}">
          <td>
            <div class="text-center">{{item.instructorName}}</div>
          </td>
        </template>

        <template #instructor="{item}">
          <td>
            <CRow>
              <CCol class="text-center">
                <CButton size="sm" color="info" shape="pill" variant="outline" @click="onchangeInstructor(item)">
                  <CIcon :name="(item.instructor == true)?'cil-check':''"/>
                </CButton>
              </CCol>
            </CRow>
          </td>
        </template>
        <template #codication="{item}">
          <td>
            <CRow>
              <CCol class="text-center">
                <CButton size="sm" color="info" shape="pill" variant="outline" @click="onchangeCodication(item)">
                  <CIcon :name="(item.codication == true)?'cil-check':''"/>
                </CButton>
              </CCol>
            </CRow>
          </td>
        </template>

        <template ##="{item}">
          <td>
            <CRow>
              <CCol>
                <CButton size="sm" color="info" shape="pill" variant="outline" @click="onRemove(item)" >
                  <CIcon name="cil-trash"/>
                </CButton>
              </CCol>
            </CRow>
          </td>
        </template>
        <template #add="{item}">
          <td>
            <CRow>
              <CCol>
                <CButton size="sm" color="info" shape="pill" variant="outline" @click="onAddInstructors(item)" >
                  <CIcon name="cil-plus"/>
                </CButton>
              </CCol>
            </CRow>
          </td>
        </template>


        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.status)">{{item.status}}</CBadge>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'TableLesssonPlans',
  props: {
    items: Array,
    fields: {
      type: Array,
    },
    icon: {
      type: String,
      default: 'cil-user'
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },

  computed: {
    ...mapGetters({
      lang: "setting/objLang",
    })
  },
  methods: {
    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    onchangeInstructor(item){
      for(var i=0; i<this.items.length; i++){
        if(this.items[i].userId == item.userId){
          this.items[i].instructor = !item.instructor
        }
      }
      this.$emit("instructor",item)
    },
    onchangeCodication(item){
      for(var i=0; i<this.items.length; i++){
        if(this.items[i].userId == item.userId){
          this.items[i].codication = !item.codication
        }
      }
      this.$emit("codication",item)
    },
    onRemove(item){
      this.items = this.items.filter(function(obj) {return obj != item;})
      this.$emit("removeInstructor",item)
    },

    onAddInstructors(item){
      this.$emit("addInstructor",item)
    }
  }
}
</script>
