<template>
  <div>
    <CRow>
      <CCol>
        <p class="mt-2"><span class="font-weight-bold">1. {{ $t('description') }} </span></p>
        <p v-if="!edit" class="mt-2"><span> {{ onCheck(setUp.description) }} </span></p>
        <quill-editor v-if="edit" :content="onCheck(setUp.description)" @change="chaneDescription"/>

        <p class="mt-2"><span class="font-weight-bold">2. {{ $t('objectives') }} </span></p>
        <p v-if="!edit" class="mt-2"><span> {{ onCheck(setUp.objectives) }} </span></p>
        <quill-editor v-if="edit" :content="onCheck(setUp.objectives)" @change="chaneObjectives"/>

        <p class="mt-2"><span class="font-weight-bold">3. {{ $t('reason') }} </span></p>
        <quill-editor :content="onCheck(data.reason)" @change="chaneReason"/>

      </CCol>
    </CRow>
    <div v-if="!disable">
      <p class="mt-2"><span class="font-weight-bold">4. {{ $t('hourse.title') }} </span></p>
      <CRow class="pl-3 pr-3">
        <CCol class="text-center border p-1">{{ $t('hourse.lecture') }}</CCol>
        <CCol class="text-center border p-1">{{ $t('hourse.lab') }}</CCol>
        <CCol class="text-center border p-1">{{ $t('hourse.selfstudy') }}</CCol>
      </CRow>
      <CRow class="pl-3 pr-3">
        <CCol class=" form-group text-center border pl-1 pr-1">{{setUp.credit.replace("(","").replace(")","").split(" ")[1].split("-")[0]}}</CCol>
        <CCol class=" form-group text-center border pl-1 pr-1">{{setUp.credit.replace("(","").replace(")","").split(" ")[1].split("-")[1]}}</CCol>
        <CCol class=" form-group text-center border pl-1 pr-1"> {{setUp.credit.replace("(","").replace(")","").split(" ")[1].split("-")[2]}}</CCol>
      </CRow>
    </div>


  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

export default {
  name: 'CourseSyllabus',
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {

      data: {
        description: [
          {
            key: "th",
            value: ""
          },
          {
            key: "en",
            value: ""

          }
        ],
        objectives: [
          {
            key: "th",
            value: ""
          },
          {
            key: "en",
            value: ""

          }
        ],
        reason: [
          {
            key: "th",
            value: ""
          },
          {
            key: "en",
            value: ""

          }
        ],
        hourse:{
          lecture: 3,
          lab: 2,
          selfstudy: 3
        }
      },
      content: ''
    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {

    onCheck(key) {
      try{
        for (var i = 0; i < key.length; i++) {
          if (key[i].key == this.lang) {
            return key[i].value;
          }
        }
      }catch (e) {

      }

    },


    onCheckIndex(key,data) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          key[i].value = data;
        }
      }
    },


    chaneDescription(html, text, quill) {
      this.onCheckIndex(this.data.description,html.html)
    },

    chaneObjectives(html, text, quill) {
      this.onCheckIndex(this.data.objectives,html.html)
    },

    chaneReason(html, text, quill) {
      this.onCheckIndex(this.data.reason,html.html)
    }

  },


  computed: {
    ...mapGetters({
      lang: "setting/objLang",
      setUp: "setUp/objSelectCours",

    })
  },

  watch: {
    // Watch content change
    content(newVal, oldVal) {
      // console.log(newVal)
      // console.log(oldVal)

    },
    setUp(value){
      console.log(value)
    },
  }
}
</script>
