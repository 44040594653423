
<template>
  <div>
    <CRow>
      <CCol sm="12" md="4">

        <CRow>
          <CCol col="4">
            <p class="mt-2 font-weight-bold">1. {{onCheck(fields.courseCode)}} :</p>
          </CCol>
          <CCol col="8">
            <multiselect
                v-model="data.courseinfo"
                :options="defualts.courses"
                label="label"
                track-by="label"
            >
              <template slot="option" slot-scope="props">
                <!--                        <img class="option__image" :src="props.option.img" alt="No Man’s Sky">-->
                <div class="option__desc">
                  <span class="option__title">{{ props.option.label }} {{ props.option.name }} {{ props.option.credit }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" md="6">
        <p class="mt-2"><span class="font-weight-bold">{{ onCheck(fields.courseTitle) }} : </span>
          {{ data.courseinfo.name }}
        </p>
      </CCol>
      <CCol sm="12" md="2">
        <p class="mt-2"><span class="font-weight-bold">{{ onCheck(fields.credit) }} : </span>
          {{ data.courseinfo.credit }}
        </p>
      </CCol>
    </CRow>

    <p class="mt-2 mb-3 font-weight-bold ">2. {{ onCheck(fields.programType)}}</p>
    <CRow class="mb-4">
      <CCol>
        <p class=" mb-3 ">{{ onCheck(fields.typeProgram)}}</p>

        <CMultiSelect
          v-if="reserve.statePrograms"
          @update="onCheckTypePrograms"
          :options="defualts.programs"
          :search="false"
          :selected="data.programs"
          :selection="false"
          inline
          optionsEmptyPlaceholder="No options placeholder"
          searchPlaceholder="Search"
          selectionType="tags"
        />
      </CCol>
      <CCol>
        <p class=" mb-3 ">{{ onCheck(fields.typeCourse)}}</p>
        <CMultiSelect
          v-if="reserve.stateCategorys"
          @update="onCheckTypeCategorys"
          :options="defualts.categorys"
          :search="false"
          :selected="data.categorys"
          :selection="false"
          inline
          optionsEmptyPlaceholder="No options placeholder"
          searchPlaceholder="Search"
          selectionType="tags"
        />
      </CCol>
    </CRow>
<!--    <CRow class="mb-4">-->
<!--      <CCol>-->
<!--        <p class=" mb-3 ">{{ onCheck(fields.typeProgram)}}</p>-->
<!--        <CMultiSelect-->
<!--          v-if="reserve.statePrograms"-->
<!--          @update="onCheckTypePrograms"-->
<!--          :options="defualts.programs"-->
<!--          :search="false"-->
<!--          :selected="data.programs"-->
<!--          :selection="false"-->
<!--          inline-->
<!--          optionsEmptyPlaceholder="No options placeholder"-->
<!--          searchPlaceholder="Search"-->
<!--          selectionType="tags"-->
<!--        />-->
<!--      </CCol>-->
<!--      <CCol>-->
<!--        <p class=" mb-3 ">{{ onCheck(fields.typeCourse)}}</p>-->
<!--        <CMultiSelect-->
<!--          v-if="reserve.stateCategorys"-->
<!--          @update="onCheckTypeCategorys"-->
<!--          :options="defualts.categorys"-->
<!--          :search="false"-->
<!--          :selected="data.categorys"-->
<!--          :selection="false"-->
<!--          inline-->
<!--          optionsEmptyPlaceholder="No options placeholder"-->
<!--          searchPlaceholder="Search"-->
<!--          selectionType="tags"-->
<!--        />-->
<!--      </CCol>-->
<!--    </CRow>-->

    <p class="mt-2 mb-3 font-weight-bold">3. {{ onCheck(fields.instructor) }} </p>
    <CRow>
      <CCol>
        <table-list
            :caption="this.onCheck(fields.instructor)"
            :items="data.instructor"
            :fields="onCheck(table.instructor)"
            @instructor="onChangeInstructor"
            @codication="onChangeCodication"
        />
      </CCol>

    </CRow>
    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="addInstructor" >
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->


    <!--    // view academicYears-->
    <p class="mt-2 mb-3 font-weight-bold">4. {{ onCheck(fields.semeterStudent) }} </p>
    <CRow class="ml-2" v-for="(item, index) in data.academicYears" v-bind:key="index">
      <CCol sm="12" md="4">
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="onCheck(fields.academicYear)" v-model="item.academicYear"/>
      </CCol>
      <CCol sm="12" md="4">
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="onCheck(fields.semester)" v-model="item.semester"/>
      </CCol>
      <CCol sm="12" md="3">
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="onCheck(fields.yearStudy)" v-model="item.yearStudy"/>
      </CCol>
      <CCol sm="12" md="1" class="text-right">
        <CButton size="sm" color="danger" shape="pill" variant="outline" @click="onRemmoveSemester(item)">
          <CIcon name="cil-minus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end view academicYears-->

    <!--    // add academicYears-->
    <CRow class="ml-2">
      <CCol sm="12" md="4">
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="this.onCheck(fields.academicYear)"
                v-model="reserve.academicYears.academicYear"/>
      </CCol>
      <CCol sm="12" md="4">
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="this.onCheck(fields.semester)" v-model="reserve.academicYears.semester"/>
      </CCol>
      <CCol sm="12" md="3">
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="this.onCheck(fields.yearStudy)" v-model="reserve.academicYears.yearStudy"/>
      </CCol>

      <CCol sm="12" md="1" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddSemester">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end add academicYears-->

    <!--    // view preRequisite-->
    <p class="mt-2 mb-3 font-weight-bold">5. {{ onCheck(fields.preRequisite) }} </p>
    <CRow class="pl-4 mb-2" v-for="(item, index) in data.preRequisite" v-bind:key="index">
      <CCol col="11">
        <CRow>
          <CCol col="2">
            <p class="mt-2 font-weight-bold">{{onCheck(fields.courseCode)}} :</p>
          </CCol>
          <CCol col="4">
            <multiselect
                v-model="item.value"
                :options="defualts.courses"
                label="label"
                track-by="label"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.label }} {{ props.option.name }} {{ props.option.credit }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
          <CCol col="6">
            <p class="mt-2 font-weight-bold">
              {{onCheck(fields.courseTitle)}} : {{ item.value.name }}
            </p>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="1" class="text-right">
        <CButton size="sm" color="danger" shape="pill" variant="outline" @click="onRemmovePreRequisite(item)">
          <CIcon name="cil-minus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end view preRequisite-->

    <!--    // Add preRequisite-->
    <CRow class="pl-4">
      <CCol col="11">
        <CRow>
          <CCol col="2">
            <p class="mt-2 font-weight-bold">{{this.onCheck(fields.courseCode)}} :</p>
          </CCol>
          <CCol col="4">
            <multiselect
                v-model="reserve.preRequisite"
                :options="defualts.courses"
                label="label"
                track-by="label"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.label }} {{ props.option.name }} {{ props.option.credit }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
          <CCol col="6">
            <p class="mt-2 font-weight-bold">
              {{this.onCheck(this.fields.courseTitle)}} : {{ reserve.preRequisite.name }}
            </p>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="1" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddPreRequisite">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add preRequisite-->

    <!--    // view coRequisite-->
    <p class="mt-2 mb-3 font-weight-bold">6. {{ onCheck(fields.coRequisite) }} </p>
    <CRow class="pl-4 mb-2" v-for="(item, index) in data.coRequisite" v-bind:key="index">
      <CCol col="11">
        <CRow>
          <CCol col="2">
            <p class="mt-2 font-weight-bold">{{onCheck(fields.courseCode)}} :</p>
          </CCol>
          <CCol col="4">
            <multiselect
                v-model="item.value"
                :options="defualts.courses"
                label="label"
                track-by="label"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.label }} {{ props.option.name }} {{ props.option.credit }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
          <CCol col="6">
            <p class="mt-2 font-weight-bold">
              {{onCheck(fields.courseTitle)}} : {{ item.value.name }}
            </p>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="1" class="text-right">
        <CButton size="sm" color="danger" shape="pill" variant="outline" @click="onRemmoveCoRequisite(item)">
          <CIcon name="cil-minus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // view coRequisite-->

    <!--    // Add coRequisite-->
    <CRow class="pl-4">
      <CCol col="11">
        <CRow>
          <CCol col="2">
            <p class="mt-2 font-weight-bold">{{this.onCheck(this.fields.courseCode)}} :</p>
          </CCol>
          <CCol col="4">
            <multiselect
                v-model="reserve.coRequisite"
                :options="defualts.courses"
                label="label"
                track-by="label"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.label }} {{ props.option.name }} {{ props.option.credit }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
          <CCol col="6">
            <p class="mt-2 font-weight-bold">
              {{this.onCheck(this.fields.courseTitle)}} : {{ reserve.coRequisite.name }}
            </p>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="1" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddCoRequisite">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add coRequisite-->

  </div>
</template>

<script>

import {mapGetters} from 'vuex'

import Multiselect from 'vue-multiselect'
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import TableList from "@/project/containers/sub/TableList";

export default {
  name: 'Generallnfimation',
  components: {TableList, Multiselect},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    tqf: {
      type: Number,
      default: 0
    },
  },
  data: function () {
    return {
      darkModal:false,
      table: {
        instructor: [{
          key: "th",
          value: [
            {key: 'no', label: 'ลำดับ',_style:'width:1%;text-align: center'},
            {key: 'fullname', label: 'ชื่อ-สกุล',_style:'width:33%'},
            {key: 'instructor', label: 'อาจารย์ผู้ประสานงานรายวิชา',_style:'width:13%; text-align: center'},
            {key: 'codication', label: 'อาจารย์ผู้รับผิดชอบรายวิชา',_style:'width:13%; text-align: center'},
            {key: 'codication', label: 'อาจารย์ผู้สอน',_style:'width:13%; text-align: center'},
            {key: '#', label: "#", _style: 'width:2%; text-align: center'}
          ]
        }, {
          key: "en",
          value: [
            {key: 'no', label: 'ID',_style:'text-align: center'},
            {key: 'fullname', label: 'Name and Surname',_style:'width:33%'},
            {key: 'instructor', label: 'cordinator',_style:'width:13%; text-align: center'},
            {key: 'codication', label: 'instruor',_style:'width:13%; text-align: center'},
            {key: '#', label: "#", _style: 'width:2%; text-align: center'}
          ]
        }]
      },
      fields:{
        schoolName: [
          {
            key: "th",
            value: "ชื่อสถาบันอุดมศึกษา"
          },
          {
            key: "en",
            value: "Name of University"
          }
        ],
        department: [
          {
            key: "th",
            value: "สำนักวิชา"
          },
          {
            key: "en",
            value: "School"
          }
        ],
        program: [
          {
            key: "th",
            value: "สาขาวิชา"
          },
          {
            key: "en",
            value: "Program"
          }
        ],
        programType:[
          {
            key: "th",
            value: "หลักสูตรและประเภทของรายวิชา"
          },
          {
            key: "en",
            value: "Program and Type of Course"
          }
        ],

        typeProgram:[
          {
            key: "th",
            value: "หลักสูตร"
          },
          {
            key: "en",
            value: "Program"
          }
        ],

        typeCourse:[
          {
            key: "th",
            value: "ประเภทของรายวิชา"
          },
          {
            key: "en",
            value: "Type of Course"
          }
        ],


        courseCode: [
          {
            key: "th",
            value: "รหัสวิชา"
          },
          {
            key: "en",
            value: "Course Code"
          }
        ],
        courseTitle: [
          {
            key: "th",
            value: "ชื่อรายวิชา"
          },
          {
            key: "en",
            value: "Course Title"
          }
        ],
        credit: [
          {
            key: "th",
            value: "หน่วยกิต"
          },
          {
            key: "en",
            value: "Credit"
          }
        ],
        instructor: [
          {
            key: "th",
            value: "อาจารย์ผู้ประสานงานรายวิชา อาจารย์ผู้รับผิดชอบรายวิชา และอาจารย์ผู้สอน"
          },
          {
            key: "en",
            value: "Course Coordinator and Course Instructor"
          }
        ],

        semeterStudent:[
          {
            key: "th",
            value: "ภาคการศึกษา / ชั้นปีที่เรียน"
          },
          {
            key: "en",
            value: "Semester Student's Year of Study"
          }
        ],
        academicYear: [
          {
            key: "th",
            value: "ปีการศึกษา"
          },
          {
            key: "en",
            value: "Academic Year"
          }
        ],
        semester: [
          {
            key: "th",
            value: "ภาคการศึกษา"
          },
          {
            key: "en",
            value: "semester"
          }
        ],
        yearStudy: [
          {
            key: "th",
            value: "ชั้นปีที่"
          },
          {
            key: "en",
            value: "Year of study"
          }
        ],
        preRequisite: [
          {
            key: "th",
            value: "รายวิชาที่ต้องเรียนมาก่อน (ถ้ามี)"
          },
          {
            key: "en",
            value: "Pre-requisite (if required)"
          }
        ],
        coRequisite: [
          {
            key: "th",
            value: "รายวิชาที่ต้องเรียนพร้อมกัน (ถ้ามี)"
          },
          {
            key: "en",
            value: "Co-requisite (if required)"
          }
        ],
      },

      defualts:{
        courses:[],
        programs:[],
        categorys:[]
      },
      data:{
        courseinfo: {},
        instructor:[],
        course:[],
        programs:[],
        categorys:[],
        academicYears:[],
        preRequisite:[],
        coRequisite:[],
      },
      reserve:{
        academicYears:{},
        preRequisite:{},
        coRequisite:{},
        statePrograms:false,
        stateCategorys:false,
      }
    }
  },
  mounted() {
    // this.$store.dispatch("course/onGetCourses")

  },

  created() {

    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {
    onchecks(item){

    },
    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    onCheckTypePrograms(item){
      // console.log("item",item)
    },

    onCheckTypeCategorys(item){
      // console.log(item)
    },

    // open dialog add profile
    addInstructor(){
      this.$store.dispatch("dialogCreate/setDialogInstructors",true)
    },

    onRemmoveSemester(item){
      this.data.academicYears = this.data.academicYears.filter(el => el !== item);

    },

    onAddSemester() {
      this.data.academicYears.push(this.reserve.academicYears)
      this.reserve.academicYears = {}
    },

    onRemmovePreRequisite(item) {
      this.data.preRequisite = this.data.preRequisite.filter(el => el !== item);
    },
    onAddPreRequisite() {
      var item = {}
      item.value = this.reserve.preRequisite
      this.data.preRequisite.push(item)
      this.reserve.preRequisite = {}
    },

    onRemmoveCoRequisite(item) {
      this.data.coRequisite = this.data.coRequisite.filter(el => el !== item);
    },
    onAddCoRequisite() {
      var item = {}
      item.value = this.reserve.coRequisite
      this.data.coRequisite.push(item)
      this.reserve.coRequisite = {}
    },



    onSelectFacultys(value, e) {
      this.$store.dispatch("course/onGetDepartment",value.code)
    },

    // Table Controll
    onChangeInstructor(value){
      console.log("instructor",value)
    },
    onChangeCodication(value){
      console.log("codication",value)
    }
    // End Table Controll

  },


  computed: {
    ...mapGetters({
      lang: "setting/objLang",
      courses : "course/objCourses",
      instructor:"course/objInstructor",
      program : "course/objProgram",
      category : "course/objCategory",
      addInstructors : "dialogCreate/onGetInstructors"

    })
  },

  watch: {
    courses(value){
      var itemCourse = [];
      for(var i=0; i<value.length; i++){
        let obj = value[i]
        var item = {};
        item.label = obj.code;
        item.name = this.onCheck(obj.name)
        item.credit = obj.credit;
        item.value = obj
        itemCourse.push(item)
      }

      this.defualts.courses = itemCourse;


      // this.data.coRequisite.push(item)
    },

    instructor(value) {

      var itemCourse = [];
      for(var i=0; i<value.length; i++){
        let obj = value[i]
        var item = {};

        item.userId = obj.userId;
        item.fullname = this.onCheck(obj.name);
        if(obj.type == 99){
          item.instructor = true;
        }else{
          item.instructor = false;
        }

        if(obj.type != 99){
          item.codication = true;
        }else{
          item.codication = false;
        }
        item.value = obj
        itemCourse.push(item)
      }
      this.data.instructor = itemCourse;
    },




    program(value){
      this.reserve.statePrograms = false;
      this.defualts.programs = this.onCheck(value)


      var vm = this
      setTimeout(function() { vm.reserve.statePrograms = true; }, 500);

    },
    category(value){
      this.reserve.stateCategorys = false;
      this.defualts.categorys = this.onCheck(value)

      var vm = this
      setTimeout(function() { vm.reserve.stateCategorys = true; }, 500);
    },

    'data.courseinfo'(value){

      this.$store.dispatch("course/onGetCoursesById",value.value)

      this.$store.dispatch("course/onGetOperations",value.value)
      this.$store.dispatch("course/onGetVerifications",value.value)

    },

    addInstructors(value){
      this.data.instructor.push(value)

    }


  }
}
</script>
