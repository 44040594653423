<template>
  <div>
    <CRow>
      <CCol>
        <div class="p-2" v-html=this.onCheck(this.fields.description)></div>
      </CCol>
    </CRow>

    <p class="mt-4"><span class="font-weight-bold">1. {{ $t('ethicsAndMorality') }} </span></p>
    <table-list
      :items="this.onCheck(this.data.ethicsAndMoralitys)" :fields="$t('ethicsAndMoralitys')"
    />
    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddDevelopPlans">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->

    <p class="mt-4"><span class="font-weight-bold">2. {{ $t('domainofAcademicKnowledge') }} </span></p>
    <table-list
      :items="this.onCheck(this.data.domainofAcademicKnowledges)" :fields="$t('domainofAcademicKnowledges')"
    />
    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddDevelopPlans">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->

    <p class="mt-4"><span class="font-weight-bold">3. {{ $t('domainofCognitiveSkill') }} </span></p>
    <table-list
      :items="this.onCheck(this.data.domainofCognitiveSkills)" :fields="$t('domainofCognitiveSkills')"
    />
    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddDevelopPlans">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->


    <p class="mt-4"><span class="font-weight-bold">4. {{ $t('domainofInterpersonalSkill') }} </span></p>
    <table-list
      :items="this.onCheck(this.data.domainofInterpersonalSkills)" :fields="$t('domainofInterpersonalSkills')"
    />
    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddDevelopPlans">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->


    <p class="mt-4"><span class="font-weight-bold">5. {{ $t('domainofNumerical') }} </span></p>
    <table-list
      :items="this.onCheck(this.data.domainofNumerical)" :fields="$t('domainofNumericals')"
    />
    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddDevelopPlans">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->

    <div v-if="disable">

      <p class="mt-4"><span class="font-weight-bold">6. {{ $t('domainofPsychomotor') }} </span></p>
      <table-list
        :items="this.onCheck(this.data.domainofPsychomotor)" :fields="$t('domainofPsychomotors')"
      />
      <!--    // Add ethicsAndMoralitys-->
      <CRow>
        <CCol col="12" class="text-right">
          <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddDevelopPlans">
            <CIcon name="cil-plus"/>
          </CButton>
        </CCol>
      </CRow>
      <!--    // end Add ethicsAndMoralitys-->
    </div>


    <CRow>
      <CCol>
        <p class="mt-2"><span class="font-weight-bold"> {{ $t('remark') }} </span></p>
      </CCol>
    </CRow>

    <CModal
      :show.sync="dialogs.darkModal"
      :no-close-on-backdrop="true"
      :centered="true"
      :title="dialogs.title"
      size="lg"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">{{dialogs.title}}</h6>
        <CButtonClose @click="dialogs.darkModal = false" class="text-white"/>
      </template>
      <template #body-wrapper>
      </template>
      <template #footer>
        <CButton @click="dialogs.darkModal = false" color="danger">Cancel</CButton>
        <CButton @click="dialogs.darkModal = false" color="success">Accept</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import TableList from "@/project/containers/sub/TableList";

Vue.use(VueQuillEditor)

export default {
  name: 'Development',
  components: { TableList},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    tqf: {
      type: Number,
      default: 0
    },
  },
  data: function () {
    return {
      fields: {
        description: [
          {
            key: "th",
            value: '<p class="ql-align-justify"><span style="color: rgb(0, 0, 0);"> &nbsp; &nbsp; </span><span style="background-color: transparent; color: rgb(0, 0, 0);">การพัฒนาผลการเรียนรู้ในมาตรฐานผลการเรียนรู้แต่ละด้านที่มุ่งหวังซึ่งต้องสอดคล้องกับที่ระบุไว้ในแผนที่แสดงการกระจายความรับผิดชอบต่อมาตรฐานผลการเรียนรู้จากหลักสูตรสู่รายวิชา (Curriculum Mapping) ตามที่กำหนดในรายละเอียดของหลักสูตร (มคอ.2) โดยมาตรฐานการเรียนรู้แต่ละด้าน ให้แสดงข้อมูลต่อไปนี้</span><span style="color: rgb(0, 0, 0);"> &nbsp; &nbsp; </span><span style="background-color: transparent; color: rgb(0, 0, 0);">ให้สอดคล้องกัน</span></p>' +
              '<ol>' +
              '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">ความรู้ หรือทักษะที่รายวิชามุ่งหวังที่จะพัฒนานักศึกษา เขียนให้สอดคล้องกับ มคอ.2</span></li>' +
              '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">คำอธิบายเกี่ยวกับวิธีการสอนที่จะใช้ในรายวิชาเพื่อพัฒนาความรู้ หรือทักษะในข้อ 1</span></li>' +
              '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">วิธีการที่จะใช้วัดและประเมินผลการเรียนรู้ของนักศึกษาในรายวิชานี้เพื่อประเมินผลการเรียนรู้ในมาตรฐานการเรียนรู้แต่ละด้านที่เกี่ยวข้อง</span></li>' +
              '</ol>'
          },
          {
            key: "en",
            value: '<p class="ql-align-justify"><span style="color: rgb(0, 0, 0);"> &nbsp; &nbsp; </span><span style="background-color: transparent; color: rgb(0, 0, 0);">The development of students’ learning outcomes in each domain should be in accordance with the curriculum mapping specified in TQF 2. In each domain, please specify the detailed description of following information</p>' +
              '<ol>' +
              '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">The Expected Learning Outcomes (ELOs) of students should be aligned with TQF 2</span></li>' +
              '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">The teaching methodology for ELOs in no.1</span></li>' +
              '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">The assessment methods of ELOs</span></li>' +
              '</ol>'
          }
        ],




      },
      data:{
        ethicsAndMoralitys: [
          {
            key: "th",
            value: [
              {
                "morality": "",
                "methodology": "",
                "methods": "",
              }
            ]
          },
          {
            key: "en",
            value: [
              {
                "morality": "",
                "methodology": "",
                "methods": "",
              }
            ]
          }
        ],
        domainofAcademicKnowledges: [
          {
            key: "th",
            value: [
              {
                "knowledge": "",
                "methodology": "",
                "methods": "",
              }
            ]
          },
          {
            key: "en",
            value: [
              {
                "knowledge": "",
                "methodology": "",
                "methods": "",
              }
            ]
          }
        ],
        domainofCognitiveSkills: [
          {
            key: "th",
            value: [
              {
                "skills": "",
                "methodology": "",
                "methods": "",
              }
            ]
          },
          {
            key: "en",
            value: [
              {
                "skills": "",
                "methodology": "",
                "methods": "",
              }
            ]
          }
        ],
        domainofInterpersonalSkills: [
          {
            key: "th",
            value: [
              {
                "ethics": "",
                "methodology": "",
                "methods": "",
              }
            ]
          },
          {
            key: "en",
            value: [
              {
                "ethics": "",
                "methodology": "",
                "methods": "",
              }
            ]
          }
        ],
        domainofNumerical: [
          {
            key: "th",
            value: [
              {
                "ethics": "",
                "methodology": "",
                "methods": "",
              }
            ]
          },
          {
            key: "en",
            value: [
              {
                "ethics": "",
                "methodology": "",
                "methods": "",
              }
            ]
          }
        ],
        domainofPsychomotor: [
          {
            key: "th",
            value: [
              {
                "ethics": "",
                "methodology": "",
                "methods": "",
              }
            ]
          },
          {
            key: "en",
            value: [
              {
                "ethics": "",
                "methodology": "",
                "methods": "",
              }
            ]
          }
        ]
      },

      dialogs:{
        darkModal: false,
        title:""

      }

    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {
    onCheckTQF(){
      switch (parseInt(this.tqf)) {
        case 4:
          return true;
        case 3:
          return false;
        default:
          return false;
      }

    },

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    chane(html, text, quill) {
      console.log(html)
    },

    onAddDevelopPlans(){
      this.$store.dispatch("dialogCreate/setDialogDevelopPlans",true)
    },

  },


  computed: {
    ...mapGetters({
      lang: "setting/objLang",
    })
  },

  watch: {
    // Watch content change
    content(newVal, oldVal) {
      // console.log(newVal)
      // console.log(oldVal)

    },
  }
}
</script>
