<template>
  <div>
    <CModal
      :show.sync="stateDialog"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"

    >
      <template #header>
        <h6 class="modal-title">{{$t('lessonPlan')}}</h6>
        <CButtonClose @click="onUpdatePlan" class="text-white"/>
      </template>
      <template #body-wrapper>
        <div class="p-2 pl-4 pr-4">
<!--          {{this.onCheck(this.fields.lessonPlans)}}-->
          <CRow>
            <CCol col="6">
              <CInput
                type="text"
                :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                :label="$t('lessonPlan')"
                v-model="reserve.search.code"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol col="6">
              <CInput
                type="text"
                :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                :label="$t('firstName')"
                v-model="reserve.search.firstname"
              />
            </CCol>

            <CCol col="6">
              <CInput
                type="text"
                :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                :label="$t('lastName')"
                v-model="reserve.search.lastname"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol class="text-right">
              <CButton @click="onSearch" color="info">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <p class="mt-2 mb-3 font-weight-bold">3. {{ $t('instructor') }} </p>
          <CRow>
            <CCol>
              <table-list
                :caption="$t('instructor')"
                :items="data.instructor"
                :fields=" $t('tableInstructor')"
                @addInstructor="addInstructor"
              />
            </CCol>

          </CRow>


        </div>
      </template>
      <template #footer>
        <CButton @click="onCancel" color="danger">{{$t('cancel')}}</CButton>
<!--        <CButton @click="onUpdatePlan" color="success">{{onCheck(fields.save)}}</CButton>-->
      </template>
    </CModal>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import TableList from "@/project/containers/sub/TableList";
Vue.use(VueQuillEditor)

// import QuillBetterTable from 'quill-better-table'
// Quill.register({
//   'modules/better-table': QuillBetterTable
// }, true)
export default {
  name: 'DialogInstructors',
  components: {TableList},
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      lang: "th",
      darkModal: true,

      fields: {



      },
      data: {
        reason:'',
        instructor:[]
      },
      reserve: {
        search:{
          code : '',
          firstname : '',
          lastname : ''
        }
      }
    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    onSearch(){
      this.$store.dispatch("instructors/onInstructor",this.reserve.search)
    },

    onUpdatePlan(){
      this.$store.dispatch("dialogCreate/setDialogInstructors",false)
    },
    onCancel(){
      this.$store.dispatch("dialogCreate/setDialogInstructors",false)
    },

    addInstructor(value){
      console.log("addInstructor",value)
      this.$store.commit('dialogCreate/onInstructors', value)

    }
  },


  computed: {
    ...mapGetters({
      stateDialog : "dialogCreate/onGetDialogInstructors",
      instructor : "instructors/objInstructor"
    })
  },

  watch: {
    instructor(value){

      var itemCourse = [];
      for(var i=0; i<value.length; i++){
        let obj = value[i]
        var item = {};

        item.userId = obj.userId;
        item.fullname = this.onCheck(obj.name);
        if(obj.type == 99){
          item.instructor = true;
        }else{
          item.instructor = false;
        }

        if(obj.type != 99){
          item.codication = true;
        }else{
          item.codication = false;
        }
        item.value = obj
        itemCourse.push(item)
      }
      this.data.instructor = itemCourse

      console.log(this.data.instructor)
    }
  }
}
</script>
