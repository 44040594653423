<template>
  <div>

    <p class="mt-4"><span class="font-weight-bold">1. {{ onCheck(fields.LessonPlan) }} </span></p>

    <table-lessson-plans
      :items="addLessonPlans"
      :fields="onCheck(table.lessonPlans)"
    />

    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddPlan">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->

    <p class="mt-4"><span class="font-weight-bold">2. {{ onCheck(fields.evaluationPlan) }} </span></p>
    <div class="mb-4" v-html="onCheck(fields.evaluationPlanDescription)"></div>


    <table-evaluation-plans
      :items="onCheck(evaluationPlans)"
      :fields="onCheck(table.evaluationPlans)"
    />
    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddEvaluationPlans">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->
    <p class="mt-4"><span class="font-weight-bold">{{ onCheck(fields.remark) }} </span></p>


  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import TableList from "@/project/containers/sub/TableList";
import DialogEvaluationPlans from "@/project/views/pages/dialog/DialogEvaluationPlans";
import DialogLessonPlans from "@/project/views/pages/dialog/DialogLessonPlans";
import TableEvaluationPlans from "@/project/containers/sub/TableEvaluationPlans";
import TableLesssonPlans from "@/project/containers/sub/TableLessonPlans";

Vue.use(VueQuillEditor)

// import QuillBetterTable from 'quill-better-table'
// Quill.register({
//   'modules/better-table': QuillBetterTable
// }, true)
export default {
  name: 'LessonPlan',
  components: {TableLesssonPlans, TableEvaluationPlans, TableList},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      darkModal: true,

      table: {
        lessonPlans: [
          {
            key: "th",
            value: [
              {key: 'weekNumber', label: "สัปดาห์ที่", _style: 'width:8%; text-align: center'},
              {key: 'topics', label: "หัวข้อการสอน / รายละเอียด", _style: 'width:30%'},
              {key: 'lectureHours', label: "จำนวนชั่วโมง", _style: 'width:8%;  text-align: center'},
              {key: 'activity', label: "กิจกรรมการสอน / สื่อการสอน", _style: 'width:35%'},
              {key: 'instructorName', label: "อาจารย์ผู้สอน", _style: 'width:23%'},
              {key: '#', label: "#", _style: 'width:2%; text-align: center'}
            ]
          },
          {
            key: "en",
            value: [
              {key: 'weekNumber', label: "สัปดาห์ที่", _style: 'width:8%; text-align: center'},
              {key: 'topics', label: "หัวข้อการสอน / รายละเอียด", _style: 'width:30%'},
              {key: 'lectureHours', label: "จำนวนชั่วโมง", _style: 'width:8%; text-align: center'},
              {key: 'activity', label: "กิจกรรมการสอน / สื่อการสอน", _style: 'width:35%'},
              {key: 'instructorName', label: "อาจารย์ผู้สอน", _style: 'width:23%'},
              {key: '#', label: "#", _style: 'width:2%; text-align: center'}
            ]
          }],
        evaluationPlans: [
          {
            key: "th",
            value: [
              {key: 'learning', label: 'ผลการเรียนรู้'},
              {key: 'method', label: 'วิธีการประเมิน'},
              {key: 'week', label: 'สัปดาห์ที่ประเมิน'},
              {key: 'proportion', label: 'สัดส่วนของการประเมิน'},
              {key: '#', label: "#", _style: 'width:2%; text-align: center'}
            ]
          }, {
            key: "en",
            value: [
              {key: 'learning', label: 'Domain of Learning'},
              {key: 'method', label: 'Assessment Method'},
              {key: 'week', label: 'Assessment Week'},
              {key: 'proportion', label: 'Assessment Proportion'},
              {key: '#', label: "#", _style: 'width:2%; text-align: center'}
            ]
          }
        ]
      },
      fields: {
        LessonPlan: [
          {
            key: "th",
            value: "แผนการสอน"
          },
          {
            key: "en",
            value: "Lesson Plan"
          }
        ],
        evaluationPlan: [
          {
            key: "th",
            value: "แผนการประเมินผลการเรียนรู้"
          },
          {
            key: "en",
            value: "Evaluation Plan"
          }
        ],
        evaluationPlanDescription: [
          {
            key: "th",
            value: " &nbsp; &nbsp; ระบุวิธีการประเมินผลการเรียนรู้หัวข้อย่อยแต่ละหัวข้อตามที่ปรากฏในแผนที่แสดงการกระจายความรับผิดชอบของรายวิชา (Curriculum Mapping) ตามที่กำหนดในรายละเอียดของหลักสูตร สัปดาห์ที่ประเมิน และสัดส่วนของการประเมิน"
          },
          {
            key: "en",
            value: "  &nbsp; &nbsp; Specify the details of assessment method and assessment proportion of each topic as stated in the curriculum mapping"
          }
        ],
        remark: [
          {
            key: "th",
            value: "หมายเหตุ: เป็นข้อมูลส่วนหนึ่งประกอบการทวนสอบผลการประเมินการเรียนรู้ทุกรายวิชาที่เปิดสอนในแต่ละภาคการศึกษา"
          },
          {
            key: "en",
            value: "Remark: This information will be a part of verification of learning outcomes of all courses of each semester."
          }
        ]
      },
      data: {
        reason: '<p class="ql-align-justify"><span style="color: rgb(0, 0, 0);"> &nbsp; &nbsp; </span><span style="background-color: transparent; color: rgb(0, 0, 0);">การพัฒนาผลการเรียนรู้ในมาตรฐานผลการเรียนรู้แต่ละด้านที่มุ่งหวังซึ่งต้องสอดคล้องกับที่ระบุไว้ในแผนที่แสดงการกระจายความรับผิดชอบต่อมาตรฐานผลการเรียนรู้จากหลักสูตรสู่รายวิชา (Curriculum Mapping) ตามที่กำหนดในรายละเอียดของหลักสูตร (มคอ.2) โดยมาตรฐานการเรียนรู้แต่ละด้าน ให้แสดงข้อมูลต่อไปนี้</span><span style="color: rgb(0, 0, 0);"> &nbsp; &nbsp; </span><span style="background-color: transparent; color: rgb(0, 0, 0);">ให้สอดคล้องกัน</span></p>' +
          '<ol>' +
          '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">ความรู้ หรือทักษะที่รายวิชามุ่งหวังที่จะพัฒนานักศึกษา เขียนให้สอดคล้องกับ มคอ.2</span></li>' +
          '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">คำอธิบายเกี่ยวกับวิธีการสอนที่จะใช้ในรายวิชาเพื่อพัฒนาความรู้ หรือทักษะในข้อ 1</span></li>' +
          '<li><span style="background-color: transparent; color: rgb(0, 0, 0);">วิธีการที่จะใช้วัดและประเมินผลการเรียนรู้ของนักศึกษาในรายวิชานี้เพื่อประเมินผลการเรียนรู้ในมาตรฐานการเรียนรู้แต่ละด้านที่เกี่ยวข้อง</span></li>' +
          '</ol>',
        evaluationPlans: [],
        lessonPlanss: [],

      },
    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
    this.$store.commit("course/setLessonPlan",this.data.lessonPlanss)
  },

  beforeDestroy() {

  },

  methods: {


    onCheckIndex(key,data) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          key[i].value = data;
        }
      }
    },

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    onCol(index) {
      switch (index) {
        case 0:
          return 1;
        case 1:
          return 1;
        case 2:
          return 2;
        case 3:
          return 2;
        case 4:
          return 4;
        case 5:
          return 2;


      }

    },

    chane(html, text, quill) {
      console.log(html)
    },

    onAddPlan(){
      this.$store.dispatch("dialogCreate/setDialogLessonPlan",true)
    },

    onAddEvaluationPlans(){
      this.$store.dispatch("dialogCreate/setDialogEvaluationPlans",true)
    }
  },


  computed: {
    ...mapGetters({
      lang: "setting/objLang",
      stateDialog: "dialogCreate/onGetDialogLessonPlan",
      addLessonPlans: "dialogCreate/onGetLessonPlans",
      lessonPlans: "course/objLessonPlan",
      evaluationPlans: "course/objLessonPlan",

    })
  },

  watch: {
    // Watch content change
    content(newVal, oldVal) {
      // console.log(newVal)
      // console.log(oldVal)

    },

    addLessonPlans(value){
      // console.log("value",value)
      this.data.lessonPlanss = value

    }

  }
}
</script>
