<template>
  <div>
    <CModal
      :show.sync="stateDialog"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">{{ onCheck(fields.lessonPlan) }}</h6>
        <CButtonClose @click="onUpdatePlan" class="text-white"/>
      </template>
      <template #body-wrapper>
        <div class="p-2 pl-4 pr-4">
          <!--          {{this.onCheck(this.fields.lessonPlans)}}-->
          <CRow>
            <CCol col="12">
              <CInput
                type="number"
                :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                :label="onCheck(fields.lessonPlans)[0].label"
                v-model="data.weekNumber"
              />
            </CCol>
            <CCol col="12">
              <CInput
                type="text"
                :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                :label="onCheck(fields.lessonPlans)[1].label"
                v-model="reserve.topics"
              />
            </CCol>
            <CCol col="12">
              <CInput
                type="number"
                :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                :label="onCheck(fields.lessonPlans)[2].label"
                v-model="data.lectureHours"
              />
            </CCol>
            <CCol col="12">
              <p class="font-weight-bold">{{ onCheck(fields.lessonPlans)[3].label }}</p>
              <quill-editor class="mb-3" :content="data.reason" @change="onEditor"/>
            </CCol>

            <CCol>
              <CInput
                type="text"
                :horizontal="{label: 'col-sm-3 font-weight-bold', input: 'col-sm-9'}"
                :label="onCheck(fields.lessonPlans)[4].label"
                v-model="data.instructor"
              />
            </CCol>
          </CRow>
        </div>
      </template>
      <template #footer>
        <CButton @click="onUpdatePlan" color="danger">{{ onCheck(fields.cancel) }}</CButton>
        <CButton @click="onUpdatePlanSubmit" color="success">{{ onCheck(fields.save) }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

// import QuillBetterTable from 'quill-better-table'
// Quill.register({
//   'modules/better-table': QuillBetterTable
// }, true)
export default {
  name: 'DialogLessonPlans',
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      darkModal: true,
      fields: {
        lessonPlan: [
          {
            key: "th",
            value: "แผนการสอน"
          },
          {
            key: "en",
            value: "Lesson Plan"
          }
        ],
        lessonPlans: [
          {
            key: "th",
            value: [
              {key: 'weekNumber', label: "สัปดาห์ที่", _style: 'width:8%; text-align: center'},
              {key: 'topics', label: "หัวข้อการสอน / รายละเอียด", _style: 'width:30%'},
              {key: 'lectureHours', label: "จำนวนชั่วโมง", _style: 'width:8%;  text-align: center'},
              {key: 'activity', label: "กิจกรรมการสอน / สื่อการสอน", _style: 'width:35%'},
              {key: 'instructor', label: "อาจารย์ผู้สอน", _style: 'width:23%'},
              {key: '#', label: "#", _style: 'width:2%; text-align: center'}
            ]
          },
          {
            key: "en",
            value: [
              {key: 'weekNumber', label: "สัปดาห์ที่", _style: 'width:8%; text-align: center'},
              {key: 'topics', label: "หัวข้อการสอน / รายละเอียด", _style: 'width:30%'},
              {key: 'lectureHours', label: "จำนวนชั่วโมง", _style: 'width:8%; text-align: center'},
              {key: 'activity', label: "กิจกรรมการสอน / สื่อการสอน", _style: 'width:35%'},
              {key: 'instructor', label: "อาจารย์ผู้สอน", _style: 'width:23%'},
              {key: '#', label: "#", _style: 'width:2%; text-align: center'}
            ]
          }],
        save: [
          {
            key: "th",
            value: "บันทึก"
          },
          {
            key: "en",
            value: "Save"
          }
        ],
        cancel: [
          {
            key: "th",
            value: "ยกเลิก"
          },
          {
            key: "en",
            value: "Cancel"
          }
        ],
      },
      reserve: {
        topics:"",
        activity:"",
      },
      data: {
        weekNumber: '',
        topics:[
          {
            key: "th",
            value: ""
          },
          {
            key: "en",
            value: ""
          }
        ],
        lectureHours:'',
        activity:[
          {
            key: "th",
            value: ""
          },
          {
            key: "en",
            value: ""
          }
        ],
        instructor:''
      }
    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {
    // onCheckIndex(key) {
    //   for (var i = 0; i < key.length; i++) {
    //     if (key[i].key == this.lang) {
    //       return i;
    //     }
    //   }
    // },

    onCheckIndex(key,data) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          key[i].value = data;
        }
      }
    },

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },
    onEditor(html, text, quill) {
      this.onCheckIndex(this.data.activity,html.html)
    },

    onUpdatePlan() {
      this.$store.dispatch("dialogCreate/setDialogLessonPlan", false)
    },

    onUpdatePlanSubmit() {

      // var index = this.onCheckIndex(this.lessonPlans)
      // this.lessonPlans[index].value.push(this.data)


      this.$store.dispatch("dialogCreate/setLessonPlans",this.data)
    },
  },


  computed: {
    ...mapGetters({

      lang: "setting/objLang",
      stateDialog: "dialogCreate/onGetDialogLessonPlan",
      lessonPlans: "course/objLessonPlan",
    })
  },

  watch: {
    lang(value){

    },
    'reserve.topics'(value){
      this.onCheckIndex(this.data.topics,value)
    }
  }
}
</script>
